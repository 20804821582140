<template>
  <div>
  <h2></h2>
    <br />
  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="application in applications" :key="application.applicant.email">
        <a :href="application.href" class="block hover:bg-gray-50">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="flex-shrink-0">
                <DocumentTextIcon class="w-7 h-7 text-gray-400" aria-hidden="true" />
              </div>
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p class="text-sm font-medium text-srRed truncate">{{ application.applicant.name }}</p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <MailIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span class="truncate">{{ application.applicant.email }}</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      Rechnung vom
                      {{ ' ' }}
                      <time :datetime="application.date">{{ application.dateFull }}</time>
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <CurrencyEuroIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                      {{ application.stage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </a>
      </li>
    </ul>
  </div>

  </div>







</template>




<script>
export default {
  name: "PageBilling",
  components: {
    CurrencyEuroIcon,
    MailIcon,
    DocumentTextIcon,
  },
  setup() {
    return {
      applications,
    }
  }



}
import { CurrencyEuroIcon, MailIcon, DocumentTextIcon, } from '@heroicons/vue/solid'

const applications = [
  {
    applicant: {
      name: 'Stadtritter',
      email: 'stadtritter@paj-gps.de',
      imageUrl:
          'https://cdn-icons-png.flaticon.com/512/888/888034.png',
    },
    date: '2020-01-07',
    dateFull: 'Januar 7, 2021',
    stage: '543,43 €',
    href: '#',
  },
  {
    applicant: {
      name: 'Stadtritter',
      email: 'stadtritter@paj-gps.de',
      imageUrl:
          'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    date: '2020-01-07',
    dateFull: 'Dezember 7, 2020',
    stage: '8234,32 €',
    href: '#',
  },
  {
    applicant: {
      name: 'Stadtritter',
      email: 'stadtritter@paj-gps.de',
      imageUrl:
          'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    date: '2020-01-07',
    dateFull: 'November 7, 2020',
    stage: '4813,34 €',
    href: '#',
  },
]














</script>

<style scoped>

</style>