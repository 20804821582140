<template>
  <div>
    <div v-if="!dataReady">
      Loading...
    </div>
    <div v-if="dataReady">
    <main class="py-5">
      <!-- Page header -->
      <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
          <div>
            <h1 class="text-2xl font-bold text-gray-900">{{$route.params.imei}}</h1>
            <p class="text-sm font-medium text-gray-500">Erstellt am {{tracker.created}}</p>
          </div>
        </div>
        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            Kartenansicht
          </button>
          <button type="button" @click="$router.push('/device/permission/'+$route.params.imei)" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-700 hover:text-white bg-srGray hover:bg-srDarkGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            Berechtigung hinzufügen
          </button>
        </div>
      </div>

      <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div  v-if="tracker.customers !== ''" class="space-y-6 lg:col-start-1 lg:col-span-2">
          <!-- Description list-->
          <Customer  v-for="customer in tracker.customers" :key="customer.id" :customer="customer"  />
        </div>

        <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1">
          <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Abostatus <span class="flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-md" :class="getBadgeStyle">{{ tracker.status.state }}</span></h2>

            <!-- Activity Feed -->
            <div class="flex flex-col justify-stretch" :class="tracker.status.state==='active' ? ['mt-0'] : ['mt-6']">
              <RadioGroup v-model="selected" v-if="tracker.status.state === 'inactive'">
                <div class="space-y-4">
                  <RadioGroupOption as="template" v-for="plan in plans" :key="plan.name" :value="plan" v-slot="{ checked, active }">
                    <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-gray-500' : '', 'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none']">
                      <div class="flex items-center">
                        <div class="text-sm">
                          <RadioGroupLabel as="p" class="font-medium text-gray-900">
                            {{ plan.name }}
                          </RadioGroupLabel>
                        </div>
                      </div>
                      <RadioGroupDescription as="div" class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                        <div class="font-medium text-gray-900">{{ plan.price }}</div>
                      </RadioGroupDescription>
                      <div :class="[active ? 'border' : 'border-2', checked ? 'border-gray-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>

              <button type="button" class="inline-flex items-center justify-center px-4 py-2 mt-5 border border-transparent text-sm font-medium rounded-md shadow-sm" :class="getSubscriptionStyle">
                {{ getSubscriptionButtonText }}
              </button>
              <button type="button" class="inline-flex items-center justify-center px-4 py-2 mt-5 border border-transparent text-sm font-medium rounded-md shadow-sm bg-srRed text-white hover:bg-srDarkRed" :class="getCancelStyle">
                Stornieren
              </button>
            </div>
<!--            <div class="bg-white shadow sm:rounded-lg mt-4">
              <SwitchGroup as="div" class="px-4 py-5 sm:p-6">
                <SwitchLabel as="h3" class="text-lg leading-6 font-medium text-gray-900" passive>
                  Abonnement automatisch verlängern
                </SwitchLabel>
                <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                  <div class="max-w-xl text-sm text-gray-500">
                    <SwitchDescription>
                      Abo wird nach Ablauf der gebuchten Zeit automatisch verlängert.
                    </SwitchDescription>
                  </div>
                  <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <Switch v-model="enabled" :class="[enabled ? 'bg-srRed' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                      <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </div>
                </div>
              </SwitchGroup>
            </div>-->
          </div>
        </section>
      </div>
    </main>

  </div>
  </div>
</template>

<script>

import Customer from "@/components/tracker/Customer";
import { ref } from 'vue'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,  } from '@headlessui/vue'
//Switch, SwitchDescription, SwitchGroup, SwitchLabel
import DataService from "../services/DataService";

/*const tracker = {
  imei: '420154835482',
  created: 1626611800,
  status: {
    state: 'inactive',
    activeUntil: 1668693091,
    activeFrom: 1636711800
  },
  customers: [
    {
      id: 4523646,
      reference: 'SR1221548',
      email: "j.doe@example.com",
      name: "John Doe",
    },
    {
      id: 4523647,
      reference: 'SR548481',
      email: "s.doe@example.com",
      name: "Simon Doe",
    }
  ]
}*/

const plans = [
  { name: '1-Jahres-Abo', price: '50€' },
  { name: '2-Jahres-Abo', price: '100€' }
]

const styleActive = ['bg-green-100', 'text-green-800'];
const styleInactive = ['bg-gray-100', 'text-gray-800'];

const subscriptionActive = ['text-white','bg-gray-400','hover:bg-gray-500'];
const subscriptionInactive = ['text-white','bg-green-500','hover:bg-green-600'];
const cancelNotPossible = ['hidden'];

export default {
  components: {
    Customer,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    //Switch,
    //SwitchDescription,
    //SwitchGroup,
    //SwitchLabel,
  },
  computed: {
    getBadgeStyle() {
      return this.tracker.status.state === 'active' ? styleActive : styleInactive;
      //return styleActive;
    },
    getSubscriptionButtonText() {
      if(this.tracker.status.state === 'active') {
        return "Aktiv bis zum " + this.tracker.status.activeUntil;
      }

      if(this.tracker.status.state === 'inactive') {
        return "Aktivieren";
      }
      return "";
    },
    getTrackerCreated() {
      return this.formatDate(this.tracker.created);
    },
    getSubscriptionStyle() {
      if(this.tracker.status.state === 'active') {
        return subscriptionActive
      } else {
        return subscriptionInactive;
      }
    },
    getCancelStyle() {
      if(this.tracker.status.state !== 'active') {
        return cancelNotPossible;
      }
      else{
        return [''];
      }
    }
  },
  methods: {
    getTrackerDetails(){
      var vm = this;
      DataService.getDeviceDetails(4209529579,'active',).then(
          function(data){
            vm.tracker = data;
            vm.dataReady = true;
          }
      )
    },
    formatDate(ts) {
      const date = new Date(ts*1000);
      return date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear();
    },
  },
  data() {
    const selected = ref(plans[0])
    const enabled = ref(false)
    return {
      tracker: [],
      plans,
      selected,
      enabled,
      dataReady: false
    }
  },
  created() {
    this.getTrackerDetails();
  }
}
</script>

<style scoped>

</style>