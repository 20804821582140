import {createApp} from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import './assets/tailwind.css'
import {createRouter, createWebHistory} from "vue-router";
import PageNavigation from "@/pages/PageNavigation";
import PageSearch from "@/pages/PageSearch";
import PageInventory from "@/pages/PageInventory";
import PageBilling from "@/pages/PageBilling";
//import PageAccount from "./pages/PageAccount";
import PageLogin from "./pages/PageLogin";
import Axios from 'axios';
import store from './store/store';
import PageForgotPassword from "./pages/PageForgotPassword";
import PageDevice from "@/pages/PageDevice";
import PageCustomerList from "./pages/PageCustomerList";
import ModalEditCustomerEmail from "./components/customer/ModalEditCustomerEmail";
import ModalEditCustomerRef from "./components/customer/ModalEditCustomerRef";
import ModalNewCustomer from "./components/customer/ModalNewCustomer";
import ModalAddPerimission from "./components/tracker/ModalAddPerimission";

const app = createApp(App);

// set auth header
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: PageNavigation,
            children: [
                {
                    path: 'search',
                    component: PageSearch
                },{
                    path: 'inventory',
                    component: PageInventory,
                },{
                    path: 'billing',
                    component: PageBilling,
                },
                {
                    path: 'device/:imei',
                    component: PageDevice,
                },
                {
                    path: 'customer',
                    component: PageCustomerList,
                },
                {
                    path: 'customer/editMail/:id',
                    component: ModalEditCustomerEmail,
                },
                {
                    path: 'customer/editRef/:id',
                    component: ModalEditCustomerRef,
                },
                {
                    path: 'customer/new',
                    component: ModalNewCustomer
                },
                {
                    path: 'device/permission/:imei',
                    component: ModalAddPerimission,
                },

            ]
        },
        {
            path: '/login',
            component: PageLogin
        },
        {
            path: '/forgotpassword',
            component: PageForgotPassword
        },
    ]
});

app.use(router);
app.use(store);
app.mount('#app');


