<template>
  <div>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-20 w-auto" :src="require('@/img/sr_vektor.svg')" alt="Stadt Ritter Logo" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Passwort vergessen?
        </h2>

      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-6" method="POST" action="#" > <!-- -->
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                E-Mail-Addresse
              </label>
              <div class="mt-1">
                <input v-model="username" id="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" />
              </div>
            </div>

            <div>
              <button  @click="test" type="submit" value="Login" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Neues Passwort anfordern
              </button>
            </div>
          </form>

          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageForgotPassword"
}
</script>

<style scoped>

</style>