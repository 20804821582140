<template>
  <div>
  <!--<h2>Inventory</h2>-->
  <TrackerList></TrackerList>
  </div>
</template>

<script>
import TrackerList from "@/components/tracker/TrackerList";
export default {
  name: "PageInventory",
  components: {TrackerList}
}
</script>

<style scoped>

</style>