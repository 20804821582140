import axios from 'axios';
import store from '../store/store';

const url = 'https://startdev.finder-portal.com/wp-json/brand';

let config = {
    "headers": {
        "Content-Type": "application/x-www-form-urlencoded",
    },
}

axios.defaults.baseURL = url;
let token = store.getters.isLoggedIn;
axios.defaults.headers.common = {'Authorization': `bearer ${token}`};
var querystring = require('querystring');

export default {
    getTrackerList() {
        return axios.get(url + '/devices')
            .then(response => response.data);
    },

    getUserList(){
        return axios.get(url+ '/user')
            .then(response => response.data);
    },

    deleteUser(id_user){
        let formData = new FormData();
        formData.append('id', id_user);

        return axios.post(url + '/deleteUser', querystring.stringify({id: id_user}),config)
            .then(response => response.data);
    },

    editUserRef(newRef, id) {
        return axios.post(url + '/updateUser', querystring.stringify({
            id: id,
            newRef: newRef
        }),config)
            .then(function(response){
                console.log(response.data);
            })
    },

    editUserMail(newMail, id) {
        return axios.post(url + '/updateUser', querystring.stringify({
            id: id,
            newEmail: newMail
        }),config)
            .then(function(response){
                console.log(response.data);
            })
    },

    createUser(email, name, pw, ref){
        return axios.post(url+ '/createUser' , querystring.stringify({
            email: email,
            username: name,
            password: pw,
            ref: ref
        }), config)
            .then(response => response.data);
    },

    getDeviceDetails(imei, status, ref){
        return axios.post(url + '/devices/details',querystring.stringify({
            imei: imei,
            status: status,
            ref: ref
        }),config)
            .then(response => response.data);
    },

    addPermission(imei, email) {
        return axios.post(url+ '/addPermission', querystring.stringify({
            email: email,
            imei: imei
        }),config)
    },
    deletePermission(imei, email) {
        return axios.post(url+ '/deletePermission', querystring.stringify({
            email: email,
            imei: imei
        }),config)
    }


};