<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-srGray">
                  <PlusSmIcon class="h-6 w-6 text-srDarkGray" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 mb-3">
                    Neue Berechtigung hinzufügen
                    <p class="text-sm flex justify-center items-center">(Nur für schon bestehende Nutzer) <QuestionMarkCircleIcon class="h-4 w-4 text-srDarkGray hover:text-gray-400" aria-hidden="true"/></p>
                  </DialogTitle>
                  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      E-Mail
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input type="text" name="email" id="email" ref="email" class="max-w-lg block w-full shadow-sm focus:ring-srGray focus:border-srGray sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-srGray text-base font-medium text-gray-700 hover:bg-srDarkGray hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:text-sm" @click="open = false; addPermission($route.params.imei)">
                  Hinzufügen
                </button>
                <button type="button" class="mt-3 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-srRed text-base font-medium text-white hover:bg-srDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="open = false; $router.push('/device/'+$route.params.imei)">
                  Abbrechen
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/vue/outline'
import DataService from "../../services/DataService";

export default {
  name: "ModalAddPermission",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    PlusSmIcon,
    QuestionMarkCircleIcon
  },
  methods: {
    addPermission(imei){
      let email = this.$refs.email.value;
      //console.log(imei, email);
      DataService.addPermission(imei, email).then(
          function(data){
            console.log(data);
          }
      );
      this.$router.push('/device/'+imei);
    },

  },
  computed: {

  },
  setup() {
    const open = ref(true)

    return {
      open,
    }
  },
}
</script>

<style scoped>

</style>