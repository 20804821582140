<template>
  <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <a @click="pageSwitchBW" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        <ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        Zurück
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <a class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">Seite {{getCurrentPage}} von {{maxPages}}</a>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a @click="pageSwitchFW" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        Weiter
        <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      </a>
    </div>
   <!-- <a href="#" class="border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium" aria-current="page">
      2
    </a>
    <a href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
      3
    </a>-->
  </nav>
</template>

<script>
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid'

export default {
  components: {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
  },
  methods: {
    pageSwitchFW() {
      if ((this.currentPage+1) < this.maxPages) {
        this.$emit("pageSwitchForward");
      }
    },
    pageSwitchBW() {
      if (this.currentPage > 0) {
        console.log("backward");
        this.$emit("pageSwitchBackward");
      }
    },
  },
    computed: {
      getCurrentPage() {
        return (this.currentPage + 1)
      }
    },
    emits: {
      pageSwitchForward: null,
      pageSwitchBackward: null
    },
    props: {
      currentPage: {
        type: Number,
        default: 0
      },
      maxPages: {
        type: Number,
        default: 0
      }
    },
    setup() {
    }
}
</script>