<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div v-if="!dataReady">
      Loading...
    </div>
    <div v-if="dataReady">

      <input class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none my-6 w-96" type="text" v-model="search" placeholder="Nach IMEI suchen"/> <br>
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-4">
        <li v-for="currentTracker in getTrackersCurrentPage()" :key="currentTracker.imei" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <Tracker :tracker="currentTracker" @click="$router.push('/device/'+currentTracker.imei)"/>
        </li>
      </ul>
      <p class="mt-6 text-sm">{{trackers.length}} Elemente (Seiten: {{getMaxPages}})</p>
    <Pagination @pageSwitchForward="pageInc" @pageSwitchBackward="pageDec" class="mt-5" :current-page="currentpage" :max-pages="getMaxPages"/>
    </div>
  </div>
</template>
<script>

import Tracker from "@/components/tracker/Tracker";
import Pagination from "@/components/tracker/Pagination";
import DataService from "@/services/DataService.js";

/*
//dummy
const trackers = [
  {
    imei: '4201848421',
    status: 'active',
    activeUntil: '12.12.2021',
    customers: [{
      name: "John Doe",
      email: "j.doe@example.com",
    }]
  },
  {
    imei: '86545215182',
    status: 'inactive',
  },
  {
    imei: '825472542452',
    status: 'inactive',
  },
  {
    imei: '878278272',
    status: 'active',
  },
  {
    imei: '84234523543',
    status: 'inactive',
  },
  {
    imei: '845245254354',
    status: 'inactive',
  },
  {
    imei: '8227852872',
    status: 'active',
  },
  {
    imei: '854728572782',
    status: 'inactive',
  },
  {
    imei: '8752727257',
    status: 'inactive',
  },
  {
    imei: '88725242542',
    status: 'active',
  },

  // More people...
]
*/
const trackersPerPage = 20;

export default {
  components: {
    Pagination,
    Tracker
  },
  mounted() {
    if (localStorage.trackers){
      this.trackers = JSON.parse(localStorage.trackers);

    }
  },
  watch:{
    trackers: {
      handler(newTracker) {
        localStorage.trackers = JSON.stringify(newTracker);
      },
      deep: true
    }
  },
  methods: {
    getTracker(){
      var vm = this;
      if(localStorage.trackers && Date.now() - JSON.parse(localStorage.getItem('time_tracker')) > 300000){
        localStorage.removeItem('trackers');
        localStorage.removeItem('time_tracker');
        location.reload();

      }else {
        if (localStorage.trackers) {
          vm.trackers = JSON.parse(localStorage.trackers);
          vm.dataReady = true;

        } else {
          DataService.getTrackerList().then(
              function (data) {
                vm.trackers = data;
                vm.dataReady = true;
                localStorage.setItem('time_tracker',JSON.stringify(Date.now()));
              }
          );
        }
      }
    },
    pageInc() {
      this.currentpage++;
    },
    pageDec() {
      this.currentpage--;
    },
    getTrackersCurrentPage() {
      const offset = trackersPerPage * this.currentpage;
      let result = [];
      for(let i = 0; i<trackersPerPage; i++) {
        if(this.filteredTracker[offset+i] !== undefined) {
          result.push(this.filteredTracker[offset+i]);
        }
      }
      return result
    }
  },
  computed: {
    getMaxPages() {
      return Math.ceil(this.trackers.length / trackersPerPage)
    },
    filteredTracker: function(){
      return this.trackers.filter((tracker) => {
        if(this.search !== ''){
          this.currentpage = 0;
        }
        return tracker.imei.match(this.search);
      });
    },

  },
  data() {
    return {
      currentpage: 0,
      dataReady: false,
      trackers: [],
      search: ''
    }
  },
  /*
  setup() {
    return {
      trackers,
    }
  },
  */
  created() {
    this.getTracker();


  }
}
</script>