<template>
  <div class="w-full flex items-center justify-between p-4 space-x-6" style="cursor: pointer">
    <div class="flex-1 truncate">
      <div class="flex items-center space-x-3">
        <h3 class="text-gray-900 text-sm font-medium truncate">{{ tracker.imei }}</h3>
        <span class="flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-full" :class="getBadgeStyle">{{ tracker.status }}</span>
      </div>
     <!-- <p class="mt-1 text-gray-500 text-sm truncate">kunden</p> -->
    </div>
  </div>
</template>

<script>


const styleActive = ['bg-green-100', 'text-green-800'];
const styleInactive = ['bg-gray-100', 'text-gray-800'];

export default {
  name: "Tracker",
  props: {
    tracker: {
      required: true
    }
  },
  computed: {
    getBadgeStyle() {
      return this.tracker.status === 'active' ? styleActive : styleInactive;
    }
  }
}
</script>

<style scoped>

</style>