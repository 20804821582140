<template>
  <div>
    <CustomerList></CustomerList>
  </div>
</template>

<script>
import CustomerList from "@/components/customer/CustomerList";

export default {
  name: "PageCustomerList",
  components: {CustomerList}
}
</script>

<style scoped>

</style>