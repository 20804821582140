<template>
  <div>
    <div v-if="!dataReady">
      Loading...
    </div>
    <div v-if="dataReady" class="ml-2">
      <div class="flex items-center">
        <button type="button" @click="$router.push('/customer/new')" class="inline-flex items-center px-3 py-2 hover:text-white border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-srGray hover:bg-srDarkGray " >
          <PlusSmIcon class="-ml-0.5 mr-2 w-5 h-5 " aria-hidden="true"></PlusSmIcon>
          Neuer Kunde
        </button>
        <input class="border-2 border-gray-300 bg-white h-10 ml-5 px-5 pr-16 rounded-lg text-sm my-6 w-96" type="text" v-model="search" placeholder="Suchbegriff eingeben"/>
        <br>
      </div>
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <li v-for="currentAccount in getAccountsCurrentPage()" :key="currentAccount.name" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
          <Account :account="currentAccount" />
          <!--@click="$router.push('/account/'+currentAccount.ref)" -->
        </li>
      </ul>
      <p class="mt-6 text-sm">{{accounts.length}} Elemente (Seiten: {{getMaxPages}})</p>
      <Pagination @pageSwitchForward="pageInc" @pageSwitchBackward="pageDec" class="mt-5" :current-page="currentpage" :max-pages="getMaxPages"/>
    </div>
  </div>
</template>

<script>


import Account from "@/components/customer/Account";
import Pagination from "@/components/tracker/Pagination";
import DataService from "@/services/DataService.js";
import { PlusSmIcon } from '@heroicons/vue/solid'

const accountsPerPage = 20;

export default {
  name: "CustomerList",
  components: {
    Pagination,
    Account,
    PlusSmIcon
  },
  mounted() {
    if(localStorage.accounts){
      this.accounts = JSON.parse(localStorage.accounts);
    }
  },
  watch:{
    accounts: {
      handler(newAccount){
        localStorage.accounts = JSON.stringify(newAccount);
      },
      deep: true
    }
  },
  methods: {
    getUser(){
      var vm = this;

      if(localStorage.accounts && Date.now() - JSON.parse(localStorage.getItem('time_accounts')) > 300000){
        localStorage.removeItem('accounts');
        localStorage.removeItem('time_accounts');
        location.reload();

      }else {
        if (localStorage.accounts) {
          vm.accounts = JSON.parse(localStorage.accounts);
          vm.dataReady = true;

        } else {
          DataService.getUserList().then(
              function (data) {
                vm.accounts = data;
                vm.dataReady = true;
                localStorage.setItem('time_accounts',JSON.stringify(Date.now()));
              }
          );
        }
      }
    },
    pageInc() {
      this.currentpage++;
    },
    pageDec() {
      this.currentpage--;
    },
    getAccountsCurrentPage() {
      const offset = accountsPerPage * this.currentpage;
      let result = [];
        for(let i = 0; i<accountsPerPage; i++) {
          if (this.filteredUser[offset + i] !== undefined) {
            result.push(this.filteredUser[offset + i]);
        }
      }
      return result
    },

  },

  computed: {
    getMaxPages() {
      return Math.ceil(this.accounts.length / accountsPerPage)
    },
    filteredUser: function(){
      return this.accounts.filter((account) => {
        if(this.search !== ''){
          this.currentpage = 0;
        }
        return account.name.toLowerCase().match(this.search.toLowerCase()) || account.ref.toLowerCase().match(this.search.toLowerCase()) || account.email.toLowerCase().match(this.search.toLowerCase());
      });
    },
  },

  data() {
    return {
      accounts: [],
      currentpage: 0,
      dataReady: false,
      search: '',

    }
  },

  created(){
    this.getUser();
  }
}
</script>

<style scoped>

</style>