<template>
  <section aria-labelledby="applicant-information-title">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
          {{customer.name}}
        </h2>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{customer.ref}}
        </p>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              E-Mail Adresse
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{customer.email}}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <button type="button" class="inline-flex items-center px-2.5 py-1.5 mt-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-700 hover:text-white bg-srGray hover:bg-srDarkGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Passwort zurücksetzen
            </button>
          </div>
          <div class="sm:col-span-1">
            <button type="button" @click="deletePermission" class="inline-flex items-center px-2.5 py-1.5 mt-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-srRed hover:bg-srDarkGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Zugriff entfernen
            </button>
          </div>
        </dl>
      </div>
    </div>
  </section>
</template>

<script>
import DataService from "../../services/DataService";
export default {
  name: "Customer",
  props: {
    customer: {

    }
  },
  computed: {
  },
  methods:{
    deletePermission(){
      DataService.deletePermission().then(function(data){
        console.log(data);
      });
    }
  }
}
</script>

<style scoped>

</style>